const axios = require("axios");
// const cors = require("cors")({
//     origin: true
// });

const googleMapsApiKey = "AIzaSyAixzxN3TnSuURj12cTclAiuECH37tMXU0";

export default new (class GoogleServices {
  async geocodeAddress(sourceAddress) {
    try {
      let address = encodeURI(sourceAddress);
      let { data } = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${googleMapsApiKey}`
      );

      // if (data.status !== "OK") {
      //     //no results
      //     return cors(address () => {
      //         response.status(200).send("No Results");
      //     });
      // }

      const geocodedLocation = data.results[0];
      const objGeolocation = {
        address: geocodedLocation.formatted_address,
        lat: geocodedLocation.geometry.location.lat,
        lng: geocodedLocation.geometry.location.lng,
      };

      return objGeolocation;
    } catch (error) {
      console.log(error);
    }
  }
})();
