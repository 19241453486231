<template>
  <v-container style="margin-bottom: 100px">
    <customer-info logo="true" dayDateWithBackBtn="true" />

    <v-row>
      <v-col md="12">
        <h1>DRIVE TO JOB</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="12" class="text-right">
        <div>
          <v-btn color="primary" dark class="mr-2">Click to Drive</v-btn>
          <v-btn color="primary" dark>Refresh</v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-row justify="center">
          <v-col sm="12" md="12">
            <google-map />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
import googleMap from "../../components/Map/Map";

export default {
  components: { CustomerInfo, googleMap },
  name: "Drive2Job",
  data() {
    return {};
  },
};
</script>

<style scoped></style>
