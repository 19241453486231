<template>
  <v-row>
    <v-col md="12">
      <GmapAutocomplete @place_changed="setPlace" />

      <button @click="addMarker">Add</button>
      <gmap-map
        ref="map"
        :center="center"
        :zoom="15"
        style="width: 100%; height: 600px"
      >
        <DirectionsRenderer
          travelMode="DRIVING"
          :origin="origin"
          :destination="destination"
        />
        <gmap-marker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="toggleInfoWindow(m, index)"
        ></gmap-marker>
        <gmap-info-window
          :options="infoOptions"
          :position="currentLocation"
          :opened="infoWinOpen"
          @closeclick="infoWinOpen = false"
        >
          <div v-html="infoContent"></div>
        </gmap-info-window>

        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center = m.position"
        />
      </gmap-map>
    </v-col>
  </v-row>
</template>

<script>
import DirectionsRenderer from "./DirectionsRenderer";
import geocodeAddress from "./GoogleServices.js";
//import gmapsInit from '../utils/gmaps';

export default {
  name: "Map",
  components: { DirectionsRenderer },

  mounted() {
    this.geocodeCustAddress();
  },

  //   async mounted() {
  //     try {
  //       const google = await gmapsInit();
  //       const geocoder = new google.maps.Geocoder();
  //       const map = new google.maps.Map(this.$el);

  //       geocoder.geocode({ address: 'Austria' }, (results, status) => {
  //         if (status !== 'OK' || !results[0]) {
  //           throw new Error(status);
  //         }

  //         map.setCenter(results[0].geometry.location);
  //         map.fitBounds(results[0].geometry.viewport);
  //       });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },

  data() {
    return {
      center: { lat: 38.98757, lng: -94.48407 },
      latLng: { lat: 0, lng: 0 },
      places: [],
      currentPlace: null,
      startingPoint: "",
      yourDestination: "7500 Blue Ridge Blvd, Kansas City, MO 64138",
      startItems: ["Chicago", "St Louis", "Joplin, MO"],
      destinationItems: ["7500 Blue Ridge Blvd, Kansas City, MO 64138"],
      infoWinOpen: false,
      infoContent: "",
      marker: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      currentLocation: { lat: 0, lng: 0 },
      customerLocation: {
        address: "",
        lat: "",
        lng: "",
      },

      markers: [
        {
          name: "7500 Blue Ridge Blvd",
          description: "7500 Blue Ridge Blvd, Kansas City, MO 64138",
          position: { lat: 38.98757, lng: -94.48407 },
        },
      ],

      map: {},
    };
  },

  computed: {
    origin() {
      return { query: this.startingPoint };
    },
    destination() {
      if (!this.yourDestination) return null;
      return { query: this.yourDestination };
    },
  },

  methods: {
    geocodeCustAddress() {
      return geocodeAddress("99 Powell Street, Brooklyn, NY 11212");
    },

    setPlace(place) {
      this.currentPlace = place;
    },

    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },

    toggleInfoWindow(marker, idx) {
      this.currentLocation = marker.position;
      this.infoContent = this.getInfoWindowContent(marker);
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    getInfoWindowContent(marker) {
      return `<div class='card'>
                        <div class='card-content'>
                            <div class='media'>
                                <div class='media-content text-center'>
                                    <p class='title is-4 mb-0'>${marker.name}</p>
                                </div>
                            </div>

                            <div class='content'>
                                ${marker.description}
                            </div>
                        </div>
                    </div>`;
    },
  },
};
</script>

<style scoped></style>
